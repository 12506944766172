//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data(){
		return {
			
		}
	},
	methods:{
		change_password(){
			this.$router.push('change-password')
		},
		log_out(){
			this.$router.push('/sing-out')
		}
	}
}
